<template>
  <div class="sonContent">
    <div class="SearchBar">
      <div style="display: flex">
        <span style="margin: 6px 10px 0 10px">工程编号/名称:</span>
        <el-input
          style="width: 200px"
          placeholder="请输入"
          size="small"
          v-model="searchBox.projectStr"
        ></el-input>
        <span style="margin: 6px 10px 0 10px">第三方施工队：</span>
        <el-input
          style="width: 200px"
          placeholder="请输入"
          size="small"
          v-model="searchBox.teamName"
        ></el-input>
        <span style="margin: 6px 10px 0 10px">现场管理员：</span>
        <el-input
          style="width: 200px"
          placeholder="请输入"
          size="small"
          v-model="searchBox.siteManager"
        ></el-input>
        <el-select
          v-model="searchBox.timeType"
          style="width: 100px; margin: 0 10px"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in tuiliaoList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          size="small"
          v-model="time"
          type="datetimerange"
          @change="timeChange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="选择开始日期时间"
          end-placeholder="选择结束日期时间"
        >
        </el-date-picker>
        <el-button
          type="primary"
          class="SearchBtn"
          size="small"
          @click="search"
          style="margin-left: 10px"
          >查询</el-button
        >
      </div>
    </div>
    <!--数据表格-->
    <el-table
      ref="tableData"
      :data="tableData"
      style="width: 100%; margin: 15px 0"
      border
      height="calc(100vh - 328px)"
      :stripe="true"
    >
      <el-table-column type="index" label="序号" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="projectNumber"
        label="工程编号"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="projectName"
        label="工程名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="第三方施工队"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="siteManager"
        label="现场管理员"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="realname"
        label="提交人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="提交时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="siteManagerTime"
        label="现场审核时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 5"
        prop="returnMode"
        label="退料方式"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <!--      <el-table-column-->
      <!--        prop="warehouseStaff"-->
      <!--        label="确认人"-->
      <!--        align="center"-->
      <!--        show-overflow-tooltip-->
      <!--      ></el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop="warehouseStaffTime"-->
      <!--        label="确认时间"-->
      <!--        align="center"-->
      <!--        show-overflow-tooltip-->
      <!--      ></el-table-column>-->
      <el-table-column width="220" label="操作" align="left">
        <template slot-scope="{ row }">
          <el-button
            v-if="hanleBtn == 3"
            type="success"
            size="small"
            @click="turnMenu(row, 4)"
            >审核</el-button
          >
          <el-button
            v-if="hanleBtn == 3"
            type="primary"
            size="small"
            @click="returnApplyInfo(row, 4)"
            >查看详情</el-button
          >
          <el-button
            v-if="hanleBtn == 5"
            type="primary"
            size="small"
            @click="returnApplyInfo(row)"
            >查看详情</el-button
          >
          <el-button
            v-if="hanleBtn == 5"
            type="success"
            size="small"
            @click="turnMenu(row, 4)"
            >{{
              row.returnMode == "调拨退料" ? "同意调拨" : "同意退料"
            }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
        :current-page="searchBox.current"
        :background="true"
        :page-sizes="[30, 50, 100]"
        :page-size="searchBox.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="详情"
      :visible.sync="detildialogVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeFrom('form')"
    >
      <div style="max-height: 600px; overflow: auto">
        <p class="detil-code">工程信息</p>
        <div style="padding: 10px; line-height: 50px">
          <div>工程编号：{{ project.projectNumber }}</div>
          <div>工程名称：{{ project.projectName }}</div>
          <div>工程类型：{{ project.projectType }}</div>
          <div>工程地点：{{ project.projectSite }}</div>
          <div>现场管理员：{{ project.siteManager }}</div>
        </div>
        <p class="detil-code">申请信息</p>
        <div style="padding: 10px">
          <div style="line-height: 50px">
            第三方施工队：{{ project.teamName }}
          </div>
          <div style="display: flex; line-height: 50px" v-if="hanleBtn == 5">
            <div style="flex: 1">退料方式：{{ project.returnMode }}</div>
            <div style="flex: 1" v-if="project.returnMode === '调拨退料'">
              调拨工程:{{ project.returnTo }}
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">提交人：{{ project.realname }}</div>
            <div style="flex: 1">提交时间：{{ project.createTime }}</div>
          </div>
        </div>
        <p class="detil-code">现场管理审核</p>
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px">
            <div v-if="hanleBtn == 3">
              <span style="margin-right: 15px">领料单签字照片：</span>
              <div v-if="project.siteManagerUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.siteManagerUrl.split(',')[0]"
                  :preview-src-list="project.siteManagerUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.siteManagerUrl.split(",").length }}张</span
                >
              </div>
            </div>
            <div v-if="hanleBtn == 5">
              <span style="margin-right: 15px">退料单签字照片：</span>
              <div v-if="project.siteManagerUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.siteManagerUrl.split(',')[0]"
                  :preview-src-list="project.siteManagerUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.siteManagerUrl.split(",").length }}张</span
                >
              </div>
            </div>
            <div v-if="returnMode == '调拨退料'">
              <span style="margin-right: 15px">调拨单据照片：</span>
              <div v-if="project.allotUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.allotUrl.split(',')[0]"
                  :preview-src-list="project.allotUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.allotUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">审核人：{{ project.siteManager }}</div>
            <div style="flex: 1">审核时间：{{ project.siteManagerTime }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detildialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="hanleBtn == 5 ? '退料审核' : '发料审核'"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="closeFrom('form')"
    >
      <el-form ref="form" v-if="dialogVisible" :model="form" label-width="80px">
        <div style="display: flex">
          <span style="color: red; padding-top: 10px">*</span>
          <el-form-item
            :label="hanleBtn == 3 ? '实际领料单据' : '实际退料单据'"
          >
            <el-upload
              action="#"
              list-type="picture-card"
              :http-request="onFileList"
              :on-remove="onDeleteFileList"
              :auto-upload="true"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </div>
        <div style="display: flex" v-if="hanleBtn == 3">
          <span style="color: red; padding-top: 10px">*</span>
          <el-form-item label="领料照片">
            <el-upload
              action="#"
              list-type="picture-card"
              :http-request="onFileList2"
              :on-remove="onDeleteFileList2"
              :auto-upload="true"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </div>

        <el-form-item label="附加描述" prop="remarks">
          <el-input type="textarea" v-model="form.remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sumit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  receiveWarehouseReviewList,
  returnWarehouseReviewList,
  returnApplyInfo,
  returnApplyWarehouseConfirm,
  receiveApplyWarehouseConfirm,
  receiveApplyInfo,
} from "../../../RequestPort/cost/cost.js";
import axios from "@/apis/designApi";
export default {
  name: "dataTabel",
  props: {
    hanleBtn: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      time: [],
      tuiliaoList: [
        { label: "提交时间", value: 1 },
        { label: "现场审核时间", value: 2 },
      ],
      dialogVisible: false,
      detildialogVisible: false,
      project: {},
      searchBox: {
        current: 1,
        size: 100,
        timeType: 1,
      },
      chargeList: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
      FileFormData: new FormData(),
      FilefileList: [],
      designFileUrl: [],
      receivePhotoUrl: [],
      form: {
        remarks: "",
        billUrl: "",
        id: null,
      },
      // rule: {
      //   archiveNumber: [
      //     { required: true, message: "请上传实际退料单据", trigger: "blur" },
      //   ],
      // },
      total: null,
      tableData: [],
      fileList: [],
      fileList2: [],
      returnMode: null,
    };
  },
  mounted() {
    // this.searchBox.keyClass = this.hanleBtn
    this.loadList();
  },
  methods: {
    search() {
      // if(this.searchBox.time.length>0){
      //   this.searchBox.startTime = this.searchBox.time[0]
      //   this.searchBox.endTime = this.searchBox.time[1]
      // }
      this.loadList();
    },
    timeChange(val) {
      if (val) {
        this.searchBox.startTime = val[0];
        this.searchBox.endTime = val[1];
      } else {
        this.searchBox.startTime = null;
        this.searchBox.endTime = null;
      }
    },
    // 判断上传的是否为图片
    beforeAvatarUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      }
      // 可以限制图片的大小
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return suffix || suffix2 || suffix3;
    },
    turnMenu(row) {
      this.form.id = row.id;
      this.dialogVisible = true;
    },
    //  上传文件
    async onFileList(files) {
      try {
        let uploadObj = new FormData();
        uploadObj.append("file", files.file);
        this.fileList.push(files.file.uid);
        let res = await axios.uploadImg(uploadObj);
        this.designFileUrl.push(res.data.url);
      } catch (e) {}
    },
    // 删除文件
    onDeleteFileList(file) {
      // this.designFileUrl = this.designFileUrl.filter((e) => e != file.url);
      let index = this.fileList.findIndex((el) => el === file.uid);
      if (index != -1) {
        this.fileList.splice(index, 1);
        this.designFileUrl.splice(index, 1);
      }
      console.log(this.designFileUrl)
    },
    //  上传文件
    async onFileList2(files) {
      try {
        let uploadObj = new FormData();
        uploadObj.append("file", files.file);
        this.fileList2.push(files.file.uid);
        let res = await axios.uploadImg(uploadObj);
        this.receivePhotoUrl.push(res.data.url);
      } catch (e) {}
    },
    // 删除文件
    onDeleteFileList2(file) {
      // this.receivePhotoUrl = this.receivePhotoUrl.filter((e) => e != file.url);
      let index = this.fileList2.findIndex((el) => el === file.uid);
      if (index != -1) {
        this.fileList2.splice(index, 1);
        this.receivePhotoUrl.splice(index, 1);
      }
    },
    async loadList() {
      let { hanleBtn } = this;
      if (hanleBtn == 3) {
        receiveWarehouseReviewList(this.searchBox).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      } else if (hanleBtn == 5) {
        returnWarehouseReviewList(this.searchBox).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      }
    },
    sumit(form) {
      let { hanleBtn } = this;
      if (this.designFileUrl && this.designFileUrl.length <= 0) {
        let msg = hanleBtn == 3?"请上传实际领料单据":"请上传实际退料单据"
        this.$message.error(msg);
        return;
      }
      if (
        hanleBtn == 3 &&
        this.receivePhotoUrl &&
        this.receivePhotoUrl.length <= 0
      ) {
        this.$message.error("请上传领料照片");
        return;
      }
      this.form.billUrl = this.designFileUrl.join(",");
      this.form.receivePhotoUrl = this.receivePhotoUrl.join(",");
      if (hanleBtn == 3) {
        receiveApplyWarehouseConfirm(this.form).then((res) => {
          if (res.code === 200) {
            this.$message.success("审核成功");
            this.dialogVisible = false;
            this.loadList();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (hanleBtn == 5) {
        returnApplyWarehouseConfirm(this.form).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.data);
            this.dialogVisible = false;
            this.loadList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    returnApplyInfo(row) {
      let { hanleBtn } = this;
      if (hanleBtn == 3) {
        receiveApplyInfo({ id: row.id }).then((res) => {
          this.project = res.data;
          (this.returnMode = null), (this.detildialogVisible = true);
        });
      } else if (hanleBtn == 5) {
        returnApplyInfo({ id: row.id }).then((res) => {
          this.project = res.data;
          this.returnMode = row.returnMode;
          this.detildialogVisible = true;
        });
      }
    },
    closeFrom(form) {
      this.FilefileList = [];
      this.designFileUrl = [];
      this.fileList = [];
      this.fileList2 = [];
      this.form.billUrl = "";
      this.form.remarks = "";
      this.form.id = null;
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList();
    },
  },
};
</script>

<style scoped>
.SearchBar {
  border: 1px solid #ecedf1;
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
</style>